import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import AppMain from "../../Layout/AppMain";
import { MainContext } from './MainContext';
import ConstructorLogin from './ConstructorLogin';

import { MsalContext, useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import './../Style/style.css';

class Main extends React.Component {
static contextType = MsalContext;

  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
      user: {},
      loadedSite: {dis: "", id: ""},
      apiRoot: window.config.apiRoot,
      haywireRoot: window.config.haywireRoot,
      connectRoot: window.config.connectRoot,
      dataModelRoot: window.config.dataModelRoot,
      edgeConfigRoot: window.config.edgeConfigRoot,
      //apimScope: "api://1f42df4c-7b07-476a-8986-d68cb5289f33/user_impersonation",
      apimScope: window.config.apimScope,
      pathList: [],
      selectedEquipList: [],
      pathHeader: "Make a Selection",
      loadedLines: [],
      menuActiveLabel: "Start Page",
      overlapExists: false,
      callbackId: null,
      msalToken: null,
    };

    this.RefreshSite = this.RefreshSite.bind(this);
  }

  componentDidMount() {

    // Reload any stored variables
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      if ("expire" in foundUser) {
        var expireTime = new Date(foundUser["expire"]);
        var curTime = new Date();
        if (expireTime > curTime) {
          this.setState({user: foundUser});
        }
      }
    } else { // If there is no classic account, check if an AD account is already logged in
      var restoreAccessPromise = this.acquireMsalAccessToken(this.context.instance)
      if (restoreAccessPromise !== null) {
        restoreAccessPromise.then(result => {
          //console.log(result);
          if (result !== null) {

            var sessionUser = {
              "username": result["idTokenClaims"]["preferred_username"],
                "dis": result["idTokenClaims"]["name"],
                "_id": result["idTokenClaims"]["oid"],
                "token": result["accessToken"],
                "expire": result["idTokenClaims"]["exp"],
                "loginType": "AD",
            }
            this.setState({user: sessionUser, msalToken: result});
          }
        });
      }
    }

    const loadedSite = localStorage.getItem("site");
    if (loadedSite) {
      const foundSite = JSON.parse(loadedSite);
      this.setState({loadedSite: foundSite});
    }

    // If a login occurs through MSAL we want to grab that token.
    const callbackId = this.context.instance.addEventCallback((message) => {
      // This will be run every time an event is emitted after registering this callback
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const result = message.payload;    
        var userObject = {
          "username": result["idTokenClaims"]["preferred_username"],
          "dis": result["idTokenClaims"]["name"],
          "_id": result["idTokenClaims"]["oid"],
          "token": result["idToken"],
          "expire": result["idTokenClaims"]["exp"],
          "loginType": "AD",
        }
        var expireTime = new Date(userObject["expire"] * 1000);
        var curTime = new Date();
        if (expireTime > curTime) {
          this.setState({user: userObject});
        }

      }
    });

    // Make sure we unbind this event.
    this.setState({callbackId: callbackId});

  }

  componentWillUnmount() {
    // This will be run on component unmount
    if (this.state.callbackId) {
        this.context.instance.removeEventCallback(this.state.callbackId);
    }
  }
  
  acquireMsalAccessToken(msalInstance) {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */   
       return null;
    }
    const request = {
        scopes: ["User.Read"],
        // scopes: ["https://bernhard-connect-apis.azure-api.net/user_impersonation",
        //          "api://1f42df4c-7b07-476a-8986-d68cb5289f33/user_impersonation"],
        account: activeAccount || accounts[0]
    };

    return msalInstance.acquireTokenSilent(request);
  };

  // updateToken(msalInstance) {
  //   const request = {
  //     scopes: ["https://bernhard-connect-apis.azure-api.net/user_impersonation"],
  //     account: msalContext.accounts[0]
  //   };
  
  //   var response = await msalContext.instance.acquireTokenSilent(request);
  //   return response;
  // }

  render() {

    if (Object.keys(this.state.user).length === 0) {
      return (
        <MainContext.Provider value={{user: this.state.user, 
          setUser: (user) => this.setState({'user': user}),
          apiRoot: this.state.apiRoot,
        }}>
          <ConstructorLogin/>
        </MainContext.Provider>
      )
    }
    else {
      return (
        <div>
          <MainContext.Provider value={{user: this.state.user, 
                                        setUser: (user) => this.setState({'user': user}),
                                        apiRoot: this.state.apiRoot,
                                        haywireRoot: this.state.haywireRoot,
                                        dataModelRoot: this.state.dataModelRoot,
                                        edgeConfigRoot: this.state.edgeConfigRoot,
                                        connectRoot: this.state.connectRoot,
                                        apimScope: this.state.apimScope,
                                        pathList: this.state.pathList, 
                                        setPathList: (paths) => this.setState({'pathList': paths}),
                                        pathHeader: this.state.pathHeader,
                                        setHeader: (header) => this.setState({'pathHeader': header}),
                                        setLoaded: (paths, trie, colName) => this.setState({'pathList': paths,
                                                                                    'pathTrie': trie,
                                                                                    'pathHeader': colName,
                                                                                    }),
                                        pathTrie: this.state.pathTrie,
                                        selectedEquipList: this.state.selectedEquipList,
                                        setEquipAndPath: (equips, paths) => this.setState({'selectedEquipList': equips, 'pathList': paths}),
                                        findWithPath: (path) => this.findWithPath(this.state.pathTrie, path, path),
                                        setEquipList: (equips) => this.setState({'selectedEquipList':equips}),
                                        loadedSite: this.state.loadedSite,
                                        setLoadedSite: (site) => this.setState({loadedSite: site}),
                                        refreshLoadedSite: () => this.RefreshSite(this.getId(this.state.loadedSite["id"])),
                                        getMongoId: (id) => this.getId(id),  
                                        loadedLines: this.state.loadedLines,
                                        setLoadedLines: (lines) => this.setState({'loadedLines': lines}),      
                                        menuActiveLabel: this.state.menuActiveLabel,
                                        setMenuActiveLabel: (label) => this.setState({"menuActiveLabel": label}),
                                        overlapExists: this.state.overlapExists,
                                        setOverlapExists: (overlapExists) => this.setState({'overlapExists': overlapExists}),
                                        msalContext: this.context, 
                                        msalToken: this.state.msalToken, 
                                      }}>
            <AppMain />
          </MainContext.Provider>
        </div>
      )
    }
  }

  // Trie search functions
  findWithPath(node, path, prefix) {
    if (node === undefined || node === null) {
      return [];
    }
    var keys = Object.keys(node);
    var paths = [];
    var foundKey = false;
    var foundAt = "";
    
    keys.forEach(key => {
      if (path.startsWith(key)) {
        foundKey = true;
        foundAt = key;
        var remaining = path.slice(key.length);
        paths = this.findWithPath(node[key], remaining, prefix);
      }
    })
    if (!foundKey && path.length > 0) { // We weren't able to find an exact match, can we find a partial match?
      keys.forEach(key => {
        if (key.startsWith(path)) {
          var lenPartialMatch = path.length;
          var finalPrefix = prefix.slice(0, prefix.length - lenPartialMatch);
          paths = this.reconstructStrings(node[key], finalPrefix + key)
        }
      })
    }
    else if (!foundKey && path.length === 0) { // Couldn't go any deeper, return results
      paths = this.reconstructStrings(node, prefix + foundAt);
      if (paths.length === 0) {
        paths = [prefix + foundAt];
      }
    }
    return paths
  } 

  reconstructStrings(node, path = "") {
    // iterate to the end, adding node components along the way
    // once a leaf has been detected, add the total to the paths and unwind a node
    // does not add the initial node, if needed, include in path

    var paths = [];

    var keys = Object.keys(node);
    keys.forEach(key => {
        if (Object.keys(node[key]).length !== 0) {
            paths = paths.concat(this.reconstructStrings(node[key], path + key));
        }
        else {
          var result = path + key;
          paths = paths.concat([result]);
        }
    })
    return paths
  }

  // TODO: Remove all references to this function since the id can be pulled from the record directly.
  getId(mongoId) { // This function used to do a lot of mongo id conversion. It is no longer needed now.
    return mongoId;
  }

  pad0(str, len) {
    var zeros = "000000000000000000000000";
    if (str.length < len) {
        return zeros.substr(0, len-str.length) + str;
    }

    return str;
  }
  
  // Get the stored build information for user from model. Sends results from the model to view to render.
  RefreshSite(id) {
    if (id !== undefined) {
      return axios.get(`${this.state.apiRoot}/siteinfo`, 
        {
          headers: {
            "Accept": 'application/json',
            "Authorization": this.state.user.token,
            "recId": id
          }
        })
        .then(response => response.data)
        .then(data => {
          var siteInfo = data.find(site => site.id === id);
          this.setState({loadedSite: siteInfo});
          localStorage.setItem('site', JSON.stringify(siteInfo));
        })
    }
  }
}

const mapStateToProp = (state) => ({

});

export default withRouter(connect(mapStateToProp)(Main));
