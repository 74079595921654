import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";

const Constructor = lazy(() => import("../../ConstructorApp/Constructor"));


const AppMain = () => {

    return (
        <Fragment>

            {/* Constructor */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load Constructor
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/constructor" component={Constructor}/>
            </Suspense>


            <Route exact path="/" render={() => (
                <Redirect to="/constructor/start"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
