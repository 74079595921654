export const msalConfig = {
    auth: {
        clientId: window.config.auth.clientId,
        authority: window.config.auth.authority, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: window.config.auth.redirectUri,
        //redirectUri: "https://localhost:4200",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        navigateToLoginRequestUrl: false
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
scopes: window.config.auth.scopes
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};