import React, { Component, Fragment } from "react";
import { MainContext } from './MainContext';
import { AdSignInButton } from './AdSignInButton';

import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

export default class ConstructorLogin extends Component {
  static contextType = MainContext;

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      remember: false,
    };

    this.attemptLogin = this.attemptLogin.bind(this);
  }

  attemptLogin() {
    fetch(this.context.apiRoot + "/userrecord/login", {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
        },
        body: JSON.stringify({username: this.state.username, password: this.state.password})
      })
      .then(response => response.json())
      .then(data => {
        if ("status" in data && data["status"] == 401) {
          alert("Invalid username or password")
        } else {
          if (this.state.remember) {
            localStorage.setItem('user', JSON.stringify(data));
          }
          this.context.setUser(data);
        }
      });
  }

  render() {
    return (
      <Fragment>
        <div className="h-100 bg-animation">
            <div className="d-flex h-100 justify-content-center align-items-center">
                <Col md="8" className="mx-auto app-login-box">
                <div className="mx-auto mb-3" />
                <div className="modal-dialog w-100 mx-auto">
                    <div className="modal-content">
                    <div className="modal-body">
                        <div className="h5 modal-title text-center">
                        <h4 className="mt-2">
                            <div>Welcome back,</div>
                            <span>Please sign in to your account below.</span>
                        </h4>
                        </div>
                    </div>
                    <div className="modal-footer clearfix">
                        <div className="float-right">
                          <AdSignInButton />
                        </div>
                    </div>
                    </div>
                </div>
                
                </Col>
            </div>
        </div>
      </Fragment>
    );
  }

}
