import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import "./assets/base.scss";
import { msalConfig } from "./authConfig";
import configureStore from "./config/configureStore";
import Main from "./ConstructorApp/Main";
import { jwtInterceptor } from "./ConstructorApp/Services/jwt.interceprot";
import "./polyfills";
import * as serviceWorker from "./serviceWorker";

const store = configureStore();
const rootElement = document.getElementById("root");

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const account = msalInstance.getAllAccounts()[0];
    msalInstance.setActiveAccount(account);

  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  jwtInterceptor(msalInstance);

  const renderApp = (Component) => {
    ReactDOM.render(
      <MsalProvider instance={msalInstance}>
          <Provider store={store}>
            <HashRouter>
              <Component />
            </HashRouter>
          </Provider>
      </MsalProvider>,
      rootElement
    );
  };

  renderApp(Main);

  if (module.hot) {
    module.hot.accept("./ConstructorApp/Main", () => {
      const NextApp = require("./ConstructorApp/Main").default;
      renderApp(NextApp);
    });
  }
  serviceWorker.unregister();
});
