import axios from 'axios';

export async function jwtInterceptor(msalInstance) {
    axios.interceptors.request.use(async(config) => {
        
        const accounts = msalInstance.getAllAccounts();
        const account = accounts[0];        
        const r = {
            scopes: [window.config.apimScope],
            account: account
          };

        var response = await msalInstance.acquireTokenSilent(r);
        config.headers.setAuthorization(`Bearer ${response.accessToken}`);
        return config;
    });
}